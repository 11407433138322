export class vintineryBrandWiseProcessConfig {

 
  
    public static brandwiseplanestimation = [ 
        {
            name: "id",
            displayName: "parentunit.intimationForm.se_no",
            type: "number",
        },
        {
          name: "createdDate",
          displayName: "parentunit.common.date",
          type: "date",
          dateFormat: "dd-MMM-yyyy hh:mm a",
        },
        {
          name: "applicationNumber",
          displayName: 'parentunit.brandlabelregistration.applicationnumber',
          type: "string",
        },
        {
            name: "unitName",
            displayName: 'Vintnery Name',
            type: "string",
        },
        {
            name: "licenseType",
            displayName: 'License Type',
            type: "string",
        },
        {
            name: "totalRawMaterialQtyUsed",
            displayName: 'Total Raw Material Estimated Qty (in Kg)',
            type: "string",
        },
        {
          name: "totalSpiritQtyUsed",
          displayName: 'Total GENA/Basewine Estimated Qty (in BL)',
          type: "string",
      },
      {
        name: "stageDesc",
        displayName: "Currently With",
        displayFcn: (item: any) =>
          item.status == "APPROVED" ||
          item.status == "DRAFT" ||
          item.status == "SUBMITTED"
            ? "-"
            : item.stageDesc,
        type: "objstring",
      },
      {
        name: "statusDesc",
        displayName: "parentunit.common.status",
        type: "options",
        badgeColors: {
          Draft: "badge-primary",
          "In Progress": "badge-secondary",
          Approved: "badge-success",
          Submitted: "badge-info",
          Rejected: "badge-danger",
          Clarification: "badge-warning",
          Cancelled: "badge-warning",
          Expired: "badge-warning",
          Suspended: "badge-warning",
        },
      },     
        ];
        public static brandwisemanufacturing =[ 
          {
              name: "id",
              displayName: "parentunit.intimationForm.se_no",
              type: "number",
          },
          {
            name: "createdDate",
            displayName: "parentunit.common.date",
            type: "date",
            dateFormat: "dd-MMM-yyyy hh:mm a",
          },
          {
            name: "applicationNumber",
            displayName: 'parentunit.brandlabelregistration.applicationnumber',
            type: "string",
          },
          {
            name: "batchNo",
            displayName: 'Batch Number',
            type: "string",
          },
          {
              name: "unitName",
              displayName: 'Vintnery Name',
              type: "string",
          },
          {
              name: "licenseType",
              displayName: 'License Type',
              type: "string",
          },
         
        {
          name: "stageDesc",
          displayName: "Currently With",
          displayFcn: (item: any) =>
            item.status == "APPROVED" ||
            item.status == "DRAFT" ||
            item.status == "SUBMITTED"
              ? "-"
              : item.stageDesc,
          type: "objstring",
        },
        {
          name: "statusDesc",
          displayName: "parentunit.common.status",
          type: "options",
          badgeColors: {
            Draft: "badge-primary",
            "In Progress": "badge-secondary",
            Approved: "badge-success",
            Submitted: "badge-info",
            Rejected: "badge-danger",
            Clarification: "badge-warning",
            Cancelled: "badge-warning",
            Expired: "badge-warning",
            Suspended: "badge-warning",
          },
        },     
          ];
          public static basewineplanestimation = [ 
            {
                name: "id",
                displayName: "parentunit.intimationForm.se_no",
                type: "number",
            },
            {
              name: "createdDate",
              displayName: "parentunit.common.date",
              type: "date",
              dateFormat: "dd-MMM-yyyy hh:mm a",
            },
            {
              name: "applicationNumber",
              displayName: 'parentunit.brandlabelregistration.applicationnumber',
              type: "string",
            },
            {
                name: "unitName",
                displayName: 'Vintnery Name',
                type: "string",
            },
            {
                name: "licenseType",
                displayName: 'License Type',
                type: "string",
            },
            {
                name: "totalRawMaterialQtyUsed",
                displayName: 'Estimated Qty (in Kg)',
                type: "string",
            },
          {
            name: "stageDesc",
            displayName: "Currently With",
            displayFcn: (item: any) =>
              item.status == "APPROVED" ||
              item.status == "DRAFT" ||
              item.status == "SUBMITTED"
                ? "-"
                : item.stageDesc,
            type: "objstring",
          },
          {
            name: "statusDesc",
            displayName: "parentunit.common.status",
            type: "options",
            badgeColors: {
              Draft: "badge-primary",
              "In Progress": "badge-secondary",
              Approved: "badge-success",
              Submitted: "badge-info",
              Rejected: "badge-danger",
              Clarification: "badge-warning",
              Cancelled: "badge-warning",
              Expired: "badge-warning",
              Suspended: "badge-warning",
            },
          },     
            ];
   
            public static basewinemanufacturing =[ 
              {
                name: "id",
                displayName: "parentunit.intimationForm.se_no",
                type: "number",
            },
            {
              name: "createdDate",
              displayName: "parentunit.common.date",
              type: "date",
              dateFormat: "dd-MMM-yyyy hh:mm a",
            },
            {
              name: "applicationNumber",
              displayName: 'parentunit.brandlabelregistration.applicationnumber',
              type: "string",
            },
            {
              name: "batchNo",
              displayName: 'Batch Number',
              type: "string",
            },
            {
                name: "unitName",
                displayName: 'Vintnery Name',
                type: "string",
            },
            {
                name: "licenseType",
                displayName: 'License Type',
                type: "string",
            },
           
          // {
          //   name: "stageDesc",
          //   displayName: "Currently With",
          //   displayFcn: (item: any) =>
          //     item.status == "APPROVED" ||
          //     item.status == "DRAFT" ||
          //     item.status == "SUBMITTED"
          //       ? "-"
          //       : item.stageDesc,
          //   type: "objstring",
          // },
          {
            name: "statusDesc",
            displayName: "parentunit.common.status",
            type: "options",
            badgeColors: {
              Draft: "badge-primary",
              "In Progress": "badge-secondary",
              Approved: "badge-success",
              Submitted: "badge-info",
              Rejected: "badge-danger",
              Clarification: "badge-warning",
              Cancelled: "badge-warning",
              Expired: "badge-warning",
              Suspended: "badge-warning",
            },
          },     
              ];
  }